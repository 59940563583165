import React, { useEffect, useState } from "react";
import "./makeHorizontal.css";
import ReactCardFlip from 'react-card-flip';
import { Box, Stack, SvgIcon, Typography, ClickAwayListener, Skeleton, CircularProgress, Tooltip } from "@mui/material";
import { ReactComponent as WarningIcon } from "./assets/warning.svg"
import { ReactComponent as claimArrow } from "./assets/arrowClaim.svg"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { motion, Variants } from 'framer-motion';
import { useRefreshStore } from "../../../../API/useRefreshStore";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/base";
import styled from "styled-components";
import CntpImage from './assets/round-cntp-image.png'
import ConetianNftImage from './assets/round-conetian-nft-image.png'
import GuardianNftImage from './assets/round-guardian-nft-image.png'
import { platform } from "../../../../API/platform";
import { toast } from "react-toastify";

const MINIMUM_FOR_GAS = 0.0003

const AirdropButton = styled(Button)<{ color?: string, fontSize?: string, textDecoration?: string, cursor?: string, disabled?: boolean }>(({ color, textDecoration, cursor, disabled, fontSize }) => ({
    backgroundColor: "transparent",
    color: color || "#8DA8FF", // Use the prop value or default to #79F8FF
    border: "none",
    boxShadow: "none",
    cursor: cursor || 'pointer',
    fontSize: fontSize || '16px',
    "&:hover": {
        backgroundColor: disabled ? '' : "rgba(121, 248, 255, 0.1)", // Optional hover effect
        textDecoration: textDecoration || "underline",
    },
    "&:focus": {
        outline: "none",
    },
}));

const ClaimAssets = () => {
    const { t } = useTranslation();
    const [claimWallDropDown, setClaimAllDropDown] = useState<boolean>(false);
    const [availableAirdrop, setAvailableAirdrop] = useState<number>(0)
    const [claimedAirdrop, setClaimedAirdrop] = useState<number>(0)
    const [isLoadingAirdrop, setIsLoadingAirdrop] = useState<number>(-1)
    const walletStore = useRefreshStore(state => state.wallets)

    //CONET gateway functions
    const [conetPlatform, setConetPlatform] = useState<any>('')
    const [workerLoading, setWorkerLoading] = useState(0)
    const conet_platform = new platform(setConetPlatform, setWorkerLoading)

    const itemVariants: Variants = {
        open: {
            opacity: 1,
            y: 0,
            transition: { type: "spring", stiffness: 300, damping: 24 }
        },
        closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
    };

    const handleAirdrop = async (e: any, index: number) => {
        setIsLoadingAirdrop(index)

        e.preventDefault()
        e.stopPropagation()

        try {
            const res = await conet_platform.redeemAirdrop(walletStore[index].keyID)

            setTimeout(() => {
                setIsLoadingAirdrop(-1)

                if (res?.err)
                    toast.error(res?.message, { position: "bottom-center", autoClose: 5000 })
                else
                    toast.success('Airdrop finished! Check your $CoNET DePIN balance.', { position: "bottom-center", autoClose: 3000 })
            }, 2000)
        } catch (error) {
            toast.error(`Error in Airdrop. Please try again later!`, { position: "bottom-center", autoClose: 3000 })
        }
    }

    useEffect(() => {
        let _availableAirdrop = 0
        let _claimedAirdrop = 0

        walletStore.forEach(async (profile) => {
            if (profile?.airdrop?.availableConetian || profile?.airdrop?.availableCntp)
                _availableAirdrop += parseFloat(profile?.airdrop?.availableConetian) + parseFloat(profile?.airdrop?.availableCntp)

            if (profile?.tokens?.conetDepin?.balance)
                _claimedAirdrop += parseFloat(profile?.tokens?.conetDepin?.balance)
        })

        setAvailableAirdrop(_availableAirdrop)
        setClaimedAirdrop(_claimedAirdrop)
    }, [walletStore])

    const renderRedeemButton = (profileIndex: number) => {
        if (!walletStore?.[profileIndex]?.airdrop)
            return <AirdropButton color={'#989899'} disabled={true} textDecoration="none" cursor='default'>REDEEM</AirdropButton>

        if (!walletStore?.[profileIndex]?.airdrop?.availableCntp && !walletStore?.[profileIndex]?.airdrop?.availableConetian)
            return <AirdropButton color={'#989899'} disabled={true} textDecoration="none" cursor='default'>REDEEM</AirdropButton>

        if (!walletStore?.[profileIndex]?.airdrop?.availableConetian && (parseFloat(walletStore?.[profileIndex]?.tokens?.cCNTP?.balance) <= 0.00001 || walletStore?.[profileIndex]?.airdrop?.availableCntp <= 0.0001))
            return <AirdropButton color={'#989899'} disabled={true} textDecoration="none" cursor='default'>REDEEM</AirdropButton>

        if (walletStore?.[profileIndex]?.tokens?.conet?.balance < MINIMUM_FOR_GAS)
            return <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} width={'100%'} height={'100%'} gap={'8px'} >
                <Tooltip title="You don't have enough CoNET Faucet">
                    <SvgIcon component={WarningIcon} viewBox="0 0 36 36" sx={{ fontSize: '16px', fill: 'none' }} />
                </Tooltip>
                <AirdropButton color={'#989899'} fontSize="12px" disabled={true} textDecoration="none" cursor='default'>REDEEM</AirdropButton>
            </Box>

        return <AirdropButton color={localStorage.getItem('mui-mode') === 'light' ? '#8DA8FF' : '#79F8FF'} onClick={(e: any) => handleAirdrop(e, profileIndex)} >REDEEM</AirdropButton>
    }

    return (
        <Stack justifyContent={'center'} py={'56px'} pb={'168px'} alignItems={'center'} position={'relative'}>
            <Stack flexDirection={['column', 'row']} justifyContent={'space-between'} gap={8} maxWidth={'919px'} alignItems={'center'}>
                <Stack alignItems={'flex-start'} >
                    <Typography mb={0} fontWeight={700} align="left" fontSize={'16px'} color={localStorage.getItem('mui-mode') === 'light' ? '#989899' : '#989899'}>Available CoNET DePIN Airdrop</Typography>
                    <Typography mb={0} fontWeight={700} align="left" fontSize={'32px'} color={localStorage.getItem('mui-mode') === 'light' ? '#000000' : '#FFFFFF'} minHeight={'32px'}>{availableAirdrop.toFixed(4) ?? 0}</Typography>
                </Stack>
                <SvgIcon component={claimArrow} viewBox="0 0 24 24" sx={{ fontSize: '24px', fill: 'none' }} />
                <Stack alignItems={'flex-start'} >
                    <Typography mb={0} fontWeight={700} align="left" fontSize={'16px'} color={localStorage.getItem('mui-mode') === 'light' ? '#989899' : '#989899'}>Redeemed CoNET DePIN</Typography>
                    <Typography mb={0} fontWeight={700} align="left" fontSize={'32px'} color={localStorage.getItem('mui-mode') === 'light' ? '#000000' : '#FFFFFF'} minHeight={'32px'}>{claimedAirdrop.toFixed(4) ?? 0}</Typography>
                </Stack>
            </Stack>
            <Box position={'relative'} width={'100%'} maxWidth={'919px'} zIndex={10} sx={{ cursor: 'pointer' }}>
                <ClickAwayListener onClickAway={() => setClaimAllDropDown(false)} >
                    <motion.nav
                        initial={false}
                        animate={claimWallDropDown ? "open" : "closed"}
                        className="menu"
                    >
                        <Stack border={localStorage.getItem('mui-mode') === 'light' ? '1px solid #8DA8FF' : '1px solid #79F8FF'} p={'18px'}
                            bgcolor={localStorage.getItem('mui-mode') === 'light' ? '#FFFFFF' : '#191919'}
                            borderRadius={'16px'}
                            width={'100%'}
                            maxWidth={'919px'}
                            onClick={() => setClaimAllDropDown(!claimWallDropDown)}
                            mt={'24px'}
                            position={'absolute'}
                            top={0}>
                            <Box display={'flex'} alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <Typography sx={{ fontSize: `12px`, color: localStorage.getItem('mui-mode') === 'light' ? "black" : "white", display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {availableAirdrop > 0 ? 'You have assets to redeem' : 'You don\'t have any assets to redeem'}
                                    </Typography>
                                </Box>

                                <Stack>
                                    <motion.div
                                        variants={{
                                            open: { rotate: 180 },
                                            closed: { rotate: 0 }
                                        }}
                                        transition={{ duration: 0.2 }}
                                        style={{ originY: 0.35, display: 'flex' }}
                                    >
                                        <SvgIcon width="20" height="20" viewBox="0 0 20 20" component={IoIosArrowDown} inheritViewBox fontSize="small" />
                                    </motion.div>
                                </Stack>
                            </Box>

                            <motion.div
                                variants={{
                                    open: {
                                        clipPath: "inset(0% 0% 0% 0% round 10px)",
                                        transition: {
                                            type: "spring",
                                            bounce: 0,
                                            duration: 0.7,
                                            delayChildren: 0.3,
                                            staggerChildren: 0.05
                                        }
                                    },
                                    closed: {
                                        clipPath: "inset(10% 50% 90% 50% round 10px)",
                                        transition: {
                                            type: "spring",
                                            bounce: 0,
                                            duration: 0.3
                                        }
                                    }
                                }}

                                style={{
                                    height: '56px',
                                    borderBottom: '1px solid #4C4C4C',
                                    display: claimWallDropDown ? 'flex' : 'none',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Box width="170px" display="flex" alignItems="center">
                                    <Typography sx={{ fontSize: '14px', letterSpacing: `1px`, opacity: 0.5 }}>
                                        Wallet Address
                                    </Typography>
                                </Box>

                                <Box width="120px" display={{ xl: 'flex', md: 'flex', sm: 'flex', xs: 'none' }} alignItems="center" px="8px">
                                    <Typography sx={{ fontSize: '14px', letterSpacing: `1px`, opacity: 0.5 }}>
                                        Assets
                                    </Typography>
                                </Box>

                                <Box width="192px" display="flex" justifyContent={'end'} alignItems="center" px="8px">
                                    <Typography sx={{ fontSize: '14px', letterSpacing: `1px`, opacity: 0.5 }}>
                                        Available $CoNET
                                    </Typography>
                                </Box>

                                <Box width="192px" display="flex" justifyContent={'end'} alignItems="center" px="8px">
                                    <Typography sx={{ fontSize: '14px', letterSpacing: `1px`, opacity: 0.5 }}>
                                        Redeemed $CoNET
                                    </Typography>
                                </Box>

                                <Box width="80px" display="flex" alignItems="center" px="8px">

                                </Box>
                            </motion.div>

                            <motion.ul
                                variants={{
                                    open: {
                                        clipPath: "inset(0% 0% 0% 0% round 10px)",
                                        transition: {
                                            type: "spring",
                                            bounce: 0,
                                            duration: 0.7,
                                            delayChildren: 0.3,
                                            staggerChildren: 0.05
                                        }
                                    },
                                    closed: {
                                        clipPath: "inset(10% 50% 90% 50% round 10px)",
                                        transition: {
                                            type: "spring",
                                            bounce: 0,
                                            duration: 0.3
                                        }
                                    }
                                }}

                                style={{
                                    pointerEvents: claimWallDropDown ? "auto" : "none",
                                    maxHeight: claimWallDropDown ? "300px" : "0",
                                    overflowY: "auto",
                                    // minHeight: claimWallDropDown ? "300px" : "0",
                                }}
                            >
                                {Array.isArray(walletStore) ? walletStore.map((profile, index) => (
                                    <motion.li key={index} custom={index} animate={'visible'} variants={itemVariants} initial='hidden' style={{ margin: '16px 0' }}>
                                        <Box flexShrink={0} display="flex" height="56px" alignItems="center" justifyContent="space-between" sx={{ flexDirection: 'row' }}>
                                            <Box width="170px" alignItems="center" sx={{ display: 'flex' }}>
                                                <Typography sx={{ fontSize: '16px', letterSpacing: `1px`, wordBreak: 'break-all' }}>
                                                    {profile && profile?.keyID?.substring(0, 2) + profile?.keyID?.substring(2, 8).toUpperCase() + '...' + profile?.keyID?.substring(profile.keyID.length - 6, profile.keyID.length).toUpperCase()}
                                                </Typography>
                                            </Box>

                                            <Box width="120px" alignItems="center" px="8px" gap="8px" sx={{ display: { xl: 'flex', md: 'flex', sm: 'flex', xs: 'none' } }}>
                                                {profile && profile?.tokens?.cCNTP?.balance > 0 ? <img src={CntpImage} width={'32px'}></img> : <></>}

                                                {profile && profile?.tokens?.GuardianPlan?.Guardian?.balance > 0 ? <img src={GuardianNftImage} width={'32px'}></img> : <></>}

                                                {profile && profile?.tokens?.ConetianPlan?.Conetian?.balance > 0 ? <img src={ConetianNftImage} width={'32px'}></img> : <></>}
                                            </Box>

                                            <Box width="192px" justifyContent={'end'} alignItems="center" px="8px" sx={{ display: { xl: 'flex', md: 'flex', sm: 'flex', xs: 'flex' } }}>
                                                <Typography color={(profile?.airdrop?.availableConetian || profile?.airdrop?.availableCntp) && (profile?.tokens?.conet?.balance < MINIMUM_FOR_GAS) ? '#A2220B' : 'auto'} sx={{ fontSize: '16px', letterSpacing: `1px`, wordBreak: 'break-all' }}>{(parseFloat(profile?.airdrop?.availableConetian || '0') + parseFloat(profile?.airdrop?.availableCntp || '0')).toFixed(4)}</Typography>
                                            </Box>

                                            <Box width="192px" justifyContent={'end'} alignItems="center" px="8px" sx={{ display: { xl: 'flex', md: 'flex', sm: 'flex', xs: 'flex' } }}>
                                                <Typography sx={{ fontSize: '16px', letterSpacing: `1px`, wordBreak: 'break-all' }}>{parseFloat(profile?.tokens?.conetDepin?.balance || '0').toFixed(4)}</Typography>
                                            </Box>

                                            <Box width="100px" justifyContent='center' alignItems="center" flexDirection={'row'} px="8px" sx={{ display: { xl: 'flex', md: 'flex', sm: 'flex', xs: 'flex' } }} >
                                                {isLoadingAirdrop === index ?
                                                    <CircularProgress size={24} color="inherit" />
                                                    :
                                                    renderRedeemButton(index)
                                                }
                                            </Box>
                                        </Box>
                                    </motion.li>
                                )
                                ) : (
                                    <>
                                        <Skeleton width={'100%'} height={'80px'} />
                                        <Skeleton width={'100%'} height={'80px'} />
                                        <Skeleton width={'100%'} height={'80px'} />
                                    </>

                                )}
                            </motion.ul>
                        </Stack>
                    </motion.nav>
                </ClickAwayListener>
            </Box>
        </Stack>
    )
};

export default ClaimAssets;
