export const settingsEn = {
  title: "Your Profile",
  createAccount: "Current Account",
  manageTitle: "Manage accounts",
  manageSubTitle: "Wallet information, avatar and tag organization",
  changing: "Changing wallet please wait...",
  tag: "Tags",
  tagAccount: "Tag your account",
  import: "Import wallet",
  save: "Save",
  insertPassword: "Insert your password",
  insertPrivate: "Insert Private Key",
  copyPrivate: "Copy Private Key",
  hideWallet: "Hide this wallet",
  transactionTitle: "Transaction",
  transactionSubTitle: "Send crypto, fast and safe",
  sendTo: "Send to",
  selectWallet: "Select Wallet",
  selectPaste: "Select or paste receiver wallet address",
  yourBalance: "Your Balance",
  transferFee: "Transfer Fees",
  gas: "Gas Fee",
  send: "Send",
  transactionHistory: "Transaction History",
  yourWallets: "Your Wallets",
  noHistory: "No Transaction History",
  owned: "Owned NFTs",
  nftSubTitles: "See your purchased NFTs",
  settings: "Settings",
  settingsSubTitle: "Choose a preferred theme, change language and password.",
  faucet: "CoNET Balances",
  faucetSubTitle: "Claim tokens with our faucet",
  theme: "Theme",
  language: "Language",
  changePassword: "Change Password",
  getPhrase: "Get Recovery Phrase",
  light: "Light",
  dark: "Dark",
  toast: "Wallet copied to the clipboard",
  enterPass: "Enter your password",
  typeNew: "Type your new password",
  newPass: "New password",
  confirmPass: "Confirm new password",
  cancel: "Cancel",
  getSRP: "Get your Secret Recovery Phrase",
  showMySRP: "Show my SRP",
  createWallet: "Create another wallet",
  profilePic: "Profile Picture",
  upload: "Upload",
  createNew: "Create new wallet",
  createButton: "Create",
  noSend: `Guardians can't send CNTP`,
  processing: "Processing",
  estimate: "Estimate Gas Fee",
  buyNfts: "Buy NFTs",
  noNft: "You don't own any NFT",
  modal: {
    title1: "The transaction",
    title2: "was successful",
    make: "Make new one",
    status1: "Starting token transfer...",
    status2: "Transfering asset to receiver wallet...",
    status3: "Waiting transfering confirmation...",
    status4: "Finalizing transaction...",
    wait1: "The purchase process can take up to 24 hours, wait until",
    wait2: "it’s finished, when it’s finished you’ll be notified",
  },
  saveSecret: "Save your Secret Recovery Phrase",
  copyPhrase: "Copy Recovery Phrase",
  buyCard: {
    sendMessage:
      "Please, copy and send us a message with the following Transaction Hash:",
  },
};

export const settingsZh = {
  title: "账户设置",
  createAccount: "当前账户",
  manageTitle: "管理账户",
  manageSubTitle: "钱包信息, 头像及标签管理",
  changing: "正在更换钱包，请稍候...",
  tag: "标签",
  tagAccount: "添加标签",
  save: "保存",
  insertPassword: "输入密码",
  copyPrivate: "复制私钥",
  hideWallet: "隐藏该钱包",
  transactionTitle: "交易",
  transactionSubTitle: "安全便携传输加密货币",
  sendTo: "传输至",
  selectWallet: "选择钱包",
  selectPaste: "选择或粘贴收款人钱包",
  yourBalance: "账户余额",
  transferFee: "传输费",
  gas: "燃料费",
  send: "传输",
  transactionHistory: "交易历史",
  yourWallets: "你的钱包",
  noHistory: "无交易记录",
  owned: "已购NFTs",
  nftSubTitles: "查看已购NFTs",
  settings: "设置",
  settingsSubTitle: "选择喜欢的主题, 修改语言, 修改密码",
  theme: "主题",
  language: "语言",
  changePassword: "修改密码",
  getPhrase: "获取助记词",
  light: "日光",
  dark: "暗夜",
  toast: "钱包已复制至粘贴板",
  enterPass: "输入现密码",
  typeNew: "输入新密码",
  newPass: "新密码",
  confirmPass: "确认新密码",
  cancel: "取消",
  getSRP: "获取助记词",
  showMySRP: "显示助记词",
  import: "导入钱包",
  createWallet: "创建另一个钱包",
  insertPrivate: "插入私人密钥",
  profilePic: "简介图片",
  upload: "上传",
  createNew: "创建新钱包",
  createButton: "创建",
  noSend: "监护人无法发送 CNTP",
  processing: "加工",
  estimate: "燃气费估算",
  buyNfts: "购买 NFT",
  noNft: "你尚未拥有任何 NFT",
  faucet: "CoNET 水龙头",
  faucetSubTitle: "通过我们的水龙头领取代币",
  modal: {
    title1: "交易",
    title2: "很成功",
    make: "新建一个",
    status1: "开始令牌转移...",
    status2: "将资产转移到接收者钱包...",
    status3: "等待转账确认...",
    status4: "完成交易...",
    wait1: "购买过程可能需要 24 小时，请等待",
    wait2: "完成，完成后会通知您",
  },
  saveSecret: "保存您的恢复秘语",
  copyPhrase: "复制恢复词组",
  buyCard: {
    sendMessage: "请复制并发送包含以下交易哈希的信息给我们:",
  },
};
