import { useEffect, useRef, useState } from "react";
import {
  styled as materialStyled,
  Box,
  Stack,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Variants, motion } from "framer-motion";
import { PurchaseButtom } from "../../miner/styles/SuperNodeBanner";

import { BridgeProfileDropdown, ProfileDropdown } from "../../bridge/bridgeProfileDropdown";
import DropdownCoin from "./DropdownCoin";
import { useTypedSelector } from "../../../../../store/store";
import DropdownFeaturedWallets from "./DropdownFeaturedWallets";
import { useUserProfile } from "../../../../../API/useUserProfile";
import {
  listeningTokenTransferHook,
  platform,
} from "../../../../../API/platform";
import TransactionResponseModal from "./TransactionResponseModal";
import DropdownTransactionHistory from "./DropdownTransactionHistory";
import { useTranslation } from "react-i18next";
import DropdownNft from "./DropdownNft";
import { renderTokenName } from "../../../../../utilities/utilities";

const getNativeToken = (network: string) => {
  if (network === 'CONET DePIN')
    return "$ETH"
  else return '$CONET'
}

const TransactionCard = ({
  receiver,
  setReceiver,
}: any) => {
  const { userProfile, setUserProfile } = useUserProfile();
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState("cCNTP");
  const [selectedNft, setSelectedNft] = useState(0);
  const [amount, setAmount] = useState(0);
  const [conetPlatform, setConetPlatform] = useState<any>("");
  const [workerLoading, setWorkerLoading] = useState(0);
  const [tokenTransferStatus, setTokenTransferStatus] = useState<number>(0);
  const [transactionHash, setTransactionHash] = useState<string>("");
  const [isTransfering, setIsTransfering] = useState<boolean>(false);
  const [isCalculatingGas, setIsCalculatingGas] = useState<boolean>(false);
  const [isGasFeeUpToDate, setIsGasFeeUpToDate] = useState(false);
  const [gasFee, setGasFee] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isCheckingReceiverAddress, setIsCheckingReceiverAddress] = useState(false);
  const [isReceiverAddressValid, setIsReceiverAddressValid] = useState(true);
  const conet_platform = new platform(setConetPlatform, setWorkerLoading);
  const [transactionHistory, setTransactionHistory] = useState<[]>([])
  const firstLoad = useRef<boolean>(true);
  const previousSelectedProfile = useRef<any>(null);

  const walletAddressChangeTimeout = useRef<any>(null);
  const gasFeeTimeout = useRef<any>(null);

  useEffect(() => {
    if (!previousSelectedProfile.current || previousSelectedProfile.current.keyID !== userProfile.keyID) {
      firstLoad.current = true;
      previousSelectedProfile.current = userProfile;
    }

    if (firstLoad.current) {
      const mainnetPassports = userProfile?.silentPassPassports?.filter((p: any) => p.network === 'CONET DePIN' && p.nftID !== 0)

      if (mainnetPassports?.length > 0) {
        setSelectedNft(mainnetPassports[0]?.nftID);
      } else {
        setSelectedNft(0);
      }

      firstLoad.current = false;

      return
    }
  }, [userProfile, selectedCoin])

  const SubmitButton = ({
    userProfile,
    isGasFeeUpToDate,
    isCalculatingGas,
    handleTransferTokenButton,
    handleEstimateGasFeeButton,
    isValid,
    amount,
    selectedCoin,
  }: any) => {
    const circleDevider =
      localStorage.getItem("mui-mode") === "light" ? "#f6f8ff" : "#303032";

    if (userProfile.isNode === true && selectedCoin === 'cCNTP') {
      return (
        <PurchaseButtom
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
            height: "50px",
            marginTop: "8px",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "600",
            fontSize: "15px",
            color: isValid ? "" : "#989899",
            padding: "0.65rem",
            borderRadius: "0.75rem",
            cursor: isValid && amount <= userProfile?.tokens[selectedCoin]?.balance ? "pointer" : "default",
            backgroundColor: circleDevider,
          }}
          onClick={handleTransferTokenButton}
          disabled={true}
        >
          {t('settings.noSend')}
        </PurchaseButtom>
      )
    }

    if (isGasFeeUpToDate || !isValid) {
      return (
        <PurchaseButtom
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
            height: "50px",
            marginTop: "8px",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "600",
            fontSize: "15px",
            color: isValid ? "" : "#989899",
            padding: "0.65rem",
            borderRadius: "0.75rem",
            cursor: isValid && amount <= userProfile?.tokens[selectedCoin]?.balance ? "pointer" : "default",
            backgroundColor: circleDevider,
          }}
          onClick={handleTransferTokenButton}
          disabled={!isValid || amount > userProfile?.tokens[selectedCoin]?.balance}
        >
          {t('settings.send')}
        </PurchaseButtom>
      )
    }

    if (!isCalculatingGas) {
      return (
        <PurchaseButtom
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
            height: "50px",
            marginTop: "8px",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "600",
            fontSize: "15px",
            padding: "0.65rem",
            borderRadius: "0.75rem",
            cursor: "pointer",
            backgroundColor: circleDevider,
          }}
          onClick={handleEstimateGasFeeButton}
          disabled={false}
        >
          {t('settings.estimate')}
        </PurchaseButtom>
      )
    }

    return (
      <PurchaseButtom
        style={{
          width: "100%",
          textAlign: "center",
          margin: "0 auto",
          height: "50px",
          marginTop: "8px",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "600",
          fontSize: "15px",
          padding: "0.65rem",
          borderRadius: "0.75rem",
          cursor: "default",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          <CircularProgress size={24} color='inherit' /> {t('settings.processing')}
        </Box>
      </PurchaseButtom>
    )
  };

  useEffect(() => {
    setIsValid(false);

    const currentPassportId = userProfile?.activePassport?.nftID.toString()
    const isCurrentPassportSelected = selectedCoin.toLowerCase() === 'silentpasspassportnft' && currentPassportId && selectedNft && currentPassportId.toString() === selectedNft.toString()

    if (amount > 0 && isReceiverAddressValid && receiver !== "" && !isCurrentPassportSelected) {
      setIsValid(true);
    }

    setIsGasFeeUpToDate(false);
  }, [amount, isReceiverAddressValid, selectedCoin, userProfile, selectedNft]);

  useEffect(() => {
    if (userProfile && userProfile.tokens) {
      const tempHistory = []

      for (const [key, value] of Object.entries(userProfile?.tokens)) {
        const newHistory = value?.history?.map((item) => {
          return {
            ...item,
            currency: key
          }
        })
        if (newHistory) tempHistory.push(...newHistory)
      }

      const orderedTempHistory = tempHistory.sort((a, b) => new Date(b?.time) - new Date(a?.time))

      setTransactionHistory(orderedTempHistory)
    }

    // Number(valor)
  }, [userProfile])

  const handleCoinChange = (tokenName: string) => {
    switch (tokenName.toLowerCase()) {
      case 'silentpasspassportnft': {
        setAmount(1);
        return
      }
      default: {
        setAmount(0);
        return
      }
    }
  }

  const handleReceiverAddressChange = async (address: string) => {
    setReceiver(address);
    clearTimeout(walletAddressChangeTimeout.current);
    setIsReceiverAddressValid(false);

    if (address !== "") {
      setIsCheckingReceiverAddress(true);

      walletAddressChangeTimeout.current = setTimeout(() => {
        return conet_platform.isAddress(address).then((result) => {
          if (result) {
            setIsReceiverAddressValid(!!result[0]);
            setIsCheckingReceiverAddress(false);
          }
        });
      }, 3000);
    } else {
      setIsCheckingReceiverAddress(false);
    }
  }

  async function handleTransferTokenButton() {
    setIsTransfering(true);

    if (userProfile !== null) {
      const nft = userProfile.silentPassPassports.find((nft: any) => nft.nftID === selectedNft)

      await conet_platform.transferToken(
        amount,
        userProfile.keyID,
        selectedCoin,
        receiver,
        nft?.nftID,
        nft?.network
      );
    }
    // setIsTransfering(false);
  }

  async function handleEstimateGasFeeButton() {
    setIsCalculatingGas(true);

    if (userProfile !== null) {

      const nft = userProfile.silentPassPassports.find((nft: any) => nft.nftID === selectedNft)

      const data = await conet_platform.estimateGas(
        amount,
        userProfile.keyID,
        selectedCoin,
        receiver,
        nft?.nftID,
        nft?.network
      );

      if (data.length >= 1) {
        setGasFee(data[1]);
        setIsGasFeeUpToDate(true);

        clearTimeout(gasFeeTimeout.current);
        gasFeeTimeout.current = setTimeout(() => {
          setIsGasFeeUpToDate(false);
        }, 60000);
      }
    }

    setIsCalculatingGas(false);
  }

  const featuredWallets = [""];

  useEffect(() => {
    listeningTokenTransferHook((cmdData: any) => {
      setTokenTransferStatus(cmdData[0])
      setTransactionHash(cmdData[1]?.hash);
    });
  });

  return (
    <>
      <Stack
        display={"flex"}
        flexDirection={["column", "row"]}
        gap={"64px"}
        width={"100%"}
      >
        <Box width={"192px"}>
          <Typography
            fontSize={"14px"}
            fontWeight={400}
            color={"#989899"}
            lineHeight={"20px"}
            letterSpacing={"0.25px"}
          >
            {t('settings.transactionSubTitle')}
          </Typography>
        </Box>
        <Stack width={"100%"}>
          {!isTransfering && userProfile.tokens ? (
            <Box id='transaction-card'>
              <BridgeProfileDropdown selectedProfile={userProfile} setSelectedProfile={setUserProfile} />

              <Box
                bgcolor={
                  localStorage.getItem("mui-mode") === "light"
                    ? "#F6F8FF"
                    : "#191919"
                }
                borderRadius={"16px"}
                padding={"16px 24px"}
                marginBottom={"24px"}
                border={isSelected ? "1px solid #676768" : ""}
                onInput={() => setIsSelected(true)}
              >
                <Typography
                  color={
                    localStorage.getItem("mui-mode") === "light"
                      ? "#676768"
                      : "#989899"
                  }
                  fontSize={"11px"}
                  fontWeight={500}
                >
                  {t('settings.sendTo')}
                </Typography>
                <DropdownFeaturedWallets
                  currentWalletKeyId={userProfile.keyID}
                  featuredWallets={featuredWallets}
                  setReceiver={setReceiver}
                  receiver={receiver}
                  handleReceiverAddressChange={handleReceiverAddressChange}
                  isCheckingReceiverAddress={isCheckingReceiverAddress}
                  isReceiverAddressValid={isReceiverAddressValid}
                />
              </Box>

              <Box
                bgcolor={
                  localStorage.getItem("mui-mode") === "light"
                    ? "#F6F8FF"
                    : "#191919"
                }
                borderRadius={"16px"}
                padding={"16px 24px"}
                border={isSelected ? "1px solid #676768" : ""}
              >
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                >
                  <Box display={"flex"} gap='4px'>
                    <TextField
                      error={amount > userProfile?.tokens[selectedCoin]?.balance}
                      helperText={amount > userProfile?.tokens[selectedCoin]?.balance ? "Insufficient balance" : ""}
                      value={amount}
                      onChange={(e) => {
                        if (userProfile?.tokens[selectedCoin]?.isNft)
                          setAmount(parseInt(e.target.value))
                        else
                          setAmount(parseFloat(e.target.value))
                      }}
                      placeholder='Type the amount'
                      variant='standard'
                      type='number'
                      sx={{
                        '& .MuiInputBase-input': {
                          fontSize: '24px',
                        },
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                    />
                  </Box>

                  <Box display={"flex"} alignItems={"center"}>
                    <DropdownCoin
                      selectedCoin={selectedCoin}
                      setSelectedCoin={setSelectedCoin}
                      handleCoinChange={handleCoinChange}
                    />
                  </Box>
                </Stack>
                <Stack
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  marginTop={'8px'}
                >
                  {!userProfile?.tokens[selectedCoin]?.hasUniqueNft ? (
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      gap='2px'
                    >
                      <Typography fontSize={'12px'} letterSpacing={'0.5px'}>{t('settings.yourBalance')}: </Typography>
                      <Typography fontSize={'12px'} letterSpacing={'0.5px'}>
                        {parseFloat(userProfile?.tokens[selectedCoin]?.balance).toFixed(6)} {renderTokenName(selectedCoin)}
                      </Typography>
                      <Box
                        padding={"4px 8px"}
                        borderRadius={"8px"}
                        bgcolor={"#8DA8FF1A"}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Typography
                          style={{ cursor: "pointer", fontSize: '12px' }}
                          color='#8DA8FF'
                          textAlign={"center"}
                          onClick={() =>
                            setAmount(userProfile.tokens[selectedCoin].balance)
                          }
                        >
                          MAX
                        </Typography>
                      </Box>
                    </Box>) : (
                    <Box display={"flex"} flexDirection={'row'} alignItems={"flex-start"} justifyContent={'center'} gap="8px" >
                      <Typography>NFT ID:</Typography>
                      <DropdownNft selectedNft={selectedNft} setSelectedNft={setSelectedNft} />
                    </Box>
                  )
                  }

                  <Typography display={["none", "block"]} fontSize={'12px'} letterSpacing={'0.5px'}>
                    {userProfile.data
                      ? userProfile.data.nickname
                      : "Anonymous User"}
                  </Typography>
                </Stack>
              </Box>

              <Stack marginY={'20px'}>
                <Typography marginBottom={"8px"}>
                  {t('settings.transferFee')}
                </Typography>
                <hr style={{ width: "100%", marginBottom: "40px" }} />
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  marginTop={"-30px"}
                >
                  <Typography
                    color={
                      localStorage.getItem("mui-mode") === "light"
                        ? "#676768"
                        : "#989899"
                    }
                  >
                    {t('settings.gas')}
                  </Typography>
                  <Typography
                    color={
                      localStorage.getItem("mui-mode") === "light"
                        ? "#676768"
                        : "#989899"
                    }
                  >
                    {userProfile?.tokens[selectedCoin].network.toLowerCase().replaceAll(' ', '') === 'conetdepin' ? '$ETH ' :
                      '$CONET '
                    }
                    {gasFee}
                  </Typography>
                </Box>

                <SubmitButton
                  userProfile={userProfile}
                  isGasFeeUpToDate={isGasFeeUpToDate}
                  handleTransferTokenButton={handleTransferTokenButton}
                  handleEstimateGasFeeButton={handleEstimateGasFeeButton}
                  isCalculatingGas={isCalculatingGas}
                  isValid={isValid}
                  amount={amount}
                  selectedCoin={selectedCoin}
                />
              </Stack>

              <DropdownTransactionHistory transactionHistory={transactionHistory} />
            </Box>
          ) : (
            <TransactionResponseModal
              setTokenTransferStatus={setTokenTransferStatus}
              transferedAmount={amount}
              tokenTransferStatus={tokenTransferStatus}
              selectedCoin={selectedCoin}
              receiver={receiver}
              setIsTransfering={setIsTransfering}
              transactionHash={transactionHash}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default TransactionCard;

