import { useState } from "react";
import { motion, Variants } from "framer-motion";
import { Typography, SvgIcon, Box } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useRefreshStore } from "../../../../../API/useRefreshStore";
import { renderTokenName } from "../../../../../utilities/utilities";

const transferrableTokens = [
    'ccntp',
    'conet',
    'conet_eth',
    'conetiannft',
    'conetianagentnft',
    'silentpasspassportnft'
]

export default function DropdownCoin({ selectedCoin, setSelectedCoin, handleCoinChange }: any) {
    const [isOpen, setIsOpen] = useState(false);

    const walletStore = useRefreshStore(state => state.wallets)

    const itemVariants: Variants = {
        open: {
            opacity: 1,
            y: 0,
            transition: { type: "spring", stiffness: 300, damping: 24 }
        },
        closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
    };

    return (
        <motion.nav
            initial={false}
            animate={isOpen ? "open" : "closed"}
            className="menu"
            style={{ cursor: 'pointer' }}
        >
            <motion.div
                whileTap={{ scale: 0.97 }}
                onClick={() => setIsOpen(!isOpen)}
                style={{ width: '100%', borderRadius: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#0D0D0D" : "#F6F1F2"} marginRight={'5px'}>{renderTokenName(selectedCoin)}</Typography>
                <motion.div
                    variants={{
                        open: { rotate: 180 },
                        closed: { rotate: 0 }
                    }}
                    transition={{ duration: 0.2 }}
                    style={{ originY: 0.55, display: 'flex' }}
                >
                    <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                </motion.div>
            </motion.div>
            <Box height={isOpen ? '150px' : '0px'}>
                <motion.ul
                    variants={{
                        open: {
                            clipPath: "inset(0% 0% 0% 0% round 10px)",
                            transition: {
                                type: "spring",
                                bounce: 0,
                                duration: 0.7,
                                delayChildren: 0.3,
                                staggerChildren: 0.05
                            }
                        },
                        closed: {
                            clipPath: "inset(10% 50% 90% 50% round 10px)",
                            transition: {
                                type: "spring",
                                bounce: 0,
                                duration: 0.3
                            }
                        }
                    }}
                    style={{ pointerEvents: isOpen ? "auto" : "none", maxHeight: '100%', overflow: 'scroll', marginTop: "8px" }}
                >
                    {walletStore && walletStore.length > 0 && Object.keys(walletStore[0]?.tokens)
                        .filter((k) => transferrableTokens.includes(k.toLowerCase()))
                        .map((k) => {
                            const tokenObj = walletStore[0].tokens[k];

                            return (
                                <motion.li
                                    key={tokenObj.name}
                                    variants={itemVariants}
                                    style={{ cursor: 'pointer', margin: "4px 0px" }}
                                    onClick={() => {
                                        setSelectedCoin(tokenObj.name);
                                        setIsOpen(false);
                                        handleCoinChange(tokenObj.name);
                                    }}
                                >
                                    {renderTokenName(tokenObj.name)}
                                </motion.li>
                            );
                        })
                    }
                </motion.ul>
            </Box>
        </motion.nav>
    );



}