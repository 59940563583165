import { Typography } from "@mui/material";
import { getRemainingTime } from "../../../../../utilities/utilities";
import FreemiumPassportNft from '../assets/FreemiumPassportNft.png'
import StandardPassportNft from '../assets/StandardPassportNft.png'
import PremiumPassportNft from '../assets/PremiumPassportNft.png'

interface INftCard {
    nftImage: string
    title: string;
    balance?: string;
    nftId?: string;
    totalSupply?: string;
    type?: string;
    expires?: number;
    expiresDays?: number;
    isPremium?: boolean;
}

export default function NftCard({ nftImage, title, balance, nftId, totalSupply, type, expires, expiresDays, isPremium }: INftCard) {

    const getPassportTitle = () => {
        if (expiresDays?.toString() === '7')
            return <Typography fontSize='12px' color={'#B1B1B2'} textAlign={'center'} fontWeight={700} pt={1}>Freemium Passport</Typography>

        if (expiresDays && expiresDays > 365)
            return <Typography fontSize='12px' color={'#B1B1B2'} textAlign={'center'} fontWeight={700} pt={1}>Unlimited Passport</Typography>

        if (isPremium)
            return <Typography fontSize='12px' color={'#B1B1B2'} textAlign={'center'} fontWeight={700} pt={1}>Premium Passport</Typography>

        return <Typography fontSize='12px' color={'#B1B1B2'} textAlign={'center'} fontWeight={700} pt={1}>Standard Passport</Typography>
    }

    const getPassportImage = () => {
        if (expiresDays?.toString() === '7')
            return FreemiumPassportNft

        if (isPremium)
            return PremiumPassportNft

        return StandardPassportNft
    }

    const getExpirationDate = () => {
        if (expires && expires > 31536000000) {
            return <Typography fontSize='10px' color={'#B1B1B2'} textAlign={'center'} fontWeight={500} pb={1}>Unlimited</Typography>
        }

        if (expires === 0) {
            return <Typography fontSize='10px' color={'#B1B1B2'} textAlign={'center'} fontWeight={500} pb={1}>Not started</Typography>
        }

        if (expires)
            return <Typography fontSize='10px' color={'#B1B1B2'} textAlign={'center'} fontWeight={500} pb={1}>Expires in {getRemainingTime(expires)}</Typography>
    }

    const getValidForDays = () => {
        if (expiresDays !== 0)
            return <>
                <Typography fontSize={'10px'}>•</Typography>
                <Typography fontSize='10px' color={'#B1B1B2'} textAlign={'center'} fontWeight={500}>Valid for {expiresDays} days</Typography>
            </>
    }

    return (
        <div style={{ width: '136px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '100%' }}>
                {type === 'passport' ? (
                    <>
                        <img src={getPassportImage()} alt="logoImge" width='100%' style={{ overflow: 'hidden', zIndex: '-1', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', border: '1px solid #474648', borderBottom: '0px' }} />

                        <div style={{ marginTop: '-7px', backgroundColor: '#000', border: '1px solid #474648', borderBottomLeftRadius: '8px', borderBottom: '1px solid #474648', borderBottomRightRadius: '8px', borderTop: '0px', height: '70px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '4px' }}>

                            {getPassportTitle()}

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>
                                {
                                    nftId && <Typography fontSize='10px' color={'#B1B1B2'} textAlign={'center'} fontWeight={500}>#{nftId}</Typography>
                                }

                                {getValidForDays()}
                            </div>

                            {getExpirationDate()}
                        </div>
                    </>
                ) : (
                    <>
                        <img src={nftImage} alt="logoImge" width='100%' style={{ overflow: 'hidden', zIndex: '-1', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', border: '1px solid #474648', borderBottom: '0px' }} />

                        <div style={{ marginTop: '-7px', backgroundColor: '#000', border: '1px solid #474648', borderBottomLeftRadius: '8px', borderBottom: '1px solid #474648', borderBottomRightRadius: '8px', borderTop: '0px', height: '70px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '4px' }}>

                            <Typography fontSize='12px' color={'#B1B1B2'} textAlign={'center'} fontWeight={700} pt={1}>{title}</Typography>

                            {
                                balance && <Typography fontSize='10px' color={'#B1B1B2'} textAlign={'center'} fontWeight={500} pb={1}>Balance: {balance}</Typography>
                            }

                            {
                                nftId && <Typography fontSize='10px' color={'#B1B1B2'} textAlign={'center'} fontWeight={500} pb={1}>#{nftId}</Typography>
                            }
                        </div>
                    </>
                )}
            </div>

            {totalSupply &&
                <div style={{ padding: '5px 10px' }}>
                    <Typography fontSize='8px'> <span style={{ fontWeight: 'bold' }}>{totalSupply}</span> NFTs available for purchase</Typography>
                </div>
            }
        </div>
    )

}