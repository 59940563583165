import { Stack, Typography, useMediaQuery } from "@mui/material";

import ConetianNft from '../assets/ConetianNft.png'
import ConetianAgentNft from '../assets/ConetianAgentNft.png'
import GuardianNft from '../assets/GuardianNft.png'
import GuardianReferralNodes from '../assets/GuardianReferralNodes.png'
import { useUserProfile } from "../../../../../API/useUserProfile";
import NftCard from "./NftCard";
import { PurchaseButtom } from "../../miner/styles/SuperNodeBanner";
import {
    setDAPPOpen,
    setMenuValue,
    setScrollToSuperNodeBanner
} from "../../../../../store/appState/appStateActions";
import store from "../../../../../store/store";
import { useTranslation } from "react-i18next";

type PurchaseNftCardProps = {
    balance: string;
    agentBalance?: string;
    isAgent: boolean
};

const circleDivider =
    localStorage.getItem("mui-mode") === "light" ? "#f6f8ff" : "#303032";

export default function PurchaseNftCard() {
    const { t } = useTranslation()
    const { userProfile, setUserProfile } = useUserProfile();
    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

    function formatNumber(input: string): string {
        return input ? input.padStart(4, '0') : '0000';
    }

    function hasConetianNft(): boolean {
        return userProfile?.tokens?.ConetianPlan?.Conetian?.balance !== '0'
    }

    function hasConetianAgentNft(): boolean {
        return userProfile?.tokens?.ConetianPlan?.Conetian_referrer?.balance !== '0'
    }

    function hasGuardianNft(): boolean {
        return userProfile?.tokens?.GuardianPlan?.Guardian?.balance !== '0'
    }

    function hasGuardianReferrerNft(): boolean {
        return userProfile?.tokens?.GuardianPlan?.Guardian_referrer?.balance !== '0'
    }

    function hasNodeNftId(): boolean {
        return userProfile?.tokens?.GuardianPlan?.Node_NFT_ID !== '0'
    }

    function hasPassportNftId(): boolean {
        return userProfile?.silentPassPassports?.length !== 0
    }

    if (hasConetianNft() || hasConetianAgentNft() || hasGuardianNft() || hasGuardianReferrerNft() || hasNodeNftId() || hasPassportNftId()) {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '16px', width: '100%', flexWrap: 'wrap' }}>

                {hasConetianNft() && (
                    <NftCard balance={formatNumber(userProfile?.tokens?.ConetianPlan?.Conetian?.balance)} nftImage={ConetianNft} title={'CoNETian NFT'} totalSupply={userProfile?.tokens?.ConetianPlan?.Conetian?.totalSupply} />
                )}

                {hasConetianAgentNft() && (
                    <NftCard balance={formatNumber(userProfile?.tokens?.ConetianPlan?.Conetian_referrer?.balance)} nftImage={ConetianAgentNft} title={'CoNETian Agent NFT'} />
                )}

                {hasGuardianNft() && (
                    <NftCard balance={formatNumber(userProfile?.tokens?.GuardianPlan?.Guardian?.balance)} nftImage={GuardianNft} title={'Guardian NFT'} />
                )}

                {hasGuardianReferrerNft() && (
                    <NftCard balance={formatNumber(userProfile?.tokens?.GuardianPlan?.Guardian_referrer?.balance)} nftImage={GuardianReferralNodes} title={'Guardian Referral Nodes'} />
                )}

                {hasNodeNftId() && (
                    <NftCard nftId={formatNumber(userProfile?.tokens?.GuardianPlan?.Node_NFT_ID)} nftImage={GuardianNft} title={'Guardian'} />
                )}

                {hasPassportNftId() &&
                    userProfile?.silentPassPassports?.filter((passport: any) => passport.nftID !== 0)?.map((passport: any) => {
                        return <NftCard nftId={formatNumber(passport?.nftID.toString())} nftImage={GuardianNft} title={'Passport'} type={'passport'} expires={passport?.expires} expiresDays={passport?.expiresDays} isPremium={passport?.premium} />
                    })
                }
            </div>

        )
    } else {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', width: '100%', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '8px', width: isMobile ? '100%' : '50%', border: '1px solid #474648', borderRadius: '16px', padding: '32px' }}>
                    <Typography style={{ fontSize: '15px', fontWeight: '600', marginBottom: '16px' }}>{t('settings.noNft')}</Typography>

                    <PurchaseButtom
                        style={{
                            width: "100%",
                            maxWidth: "170px",
                            textAlign: "center",
                            margin: "0 auto",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "15px",
                            padding: "0.65rem",
                            borderRadius: "0.75rem",
                            cursor: "pointer",
                            backgroundColor: circleDivider,
                        }}
                        disabled={false}
                        onClick={() => {
                            store.dispatch(setMenuValue(0));
                            store.dispatch(setScrollToSuperNodeBanner(true));
                            return store.dispatch(setDAPPOpen("miner"));
                        }}
                    >
                        {t('settings.buyNfts')}
                    </PurchaseButtom>
                </div>
            </div>
        )
    }
}